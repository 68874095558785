import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acacia-works',
  templateUrl: './acacia-works.component.html',
  styleUrls: ['./acacia-works.component.scss']
})
export class AcaciaWorksComponent implements OnInit {

  array_of_links = [
    "assets/official_img/IMG_20190331_213755.jpg", "assets/official_img/mp_for_web-2.jpg",
    "assets/official_img/Screenshot_20200216_213200.jpg", "assets/official_img/IMG_20201029_154705.jpg", 
    "assets/official_img/Screenshot_20201029_151708.jpg"
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
