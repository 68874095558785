<nz-layout class="layout">
  <nz-header>
    <div style="display: flex;  align-items: center;">
      <span class="logo">
        <img src="assets/official_img/acacia0.png" alt="Acacia Works" />
      </span>
      <span id="page-title">
        <h1>Acacia Works</h1>
      </span>
      <span id="page-menu">
        <ul nz-menu nzMode="horizontal">
          <li nz-menu-item>
            <a href="#home">Home</a>
          </li>
          <li nz-menu-item>
            <a href="#about">About us</a>
          </li>
          <li nz-menu-item>
            <a href="#service">Products and services</a>
          </li>
          <li nz-menu-item>
            <a href="#contact">Contact us</a>
          </li>
        </ul>
      </span>
      
    </div>
  </nz-header>

  <nz-content>

    <div class="inner-content">
      <nz-carousel nzAutoPlay
      [nzEffect]="'fade'" [nzAutoPlaySpeed]=4000
      [nzDots]=false >
        <div nz-carousel-content *ngFor="let index of array_of_links">
          <img src={{index}} />
        </div>
      </nz-carousel>
      <nz-divider></nz-divider>
      <div class="uniform">
        <h2 id="about">About Acacia Works</h2>
        <!--Introduction(Home) content under here-->
        <!--About us content under here-->
        <p>With the ever-changing challenges that surround the usage of water in today’s world, there has
          never been a greater demand for innovative, efficient and cost-saving solutions for the way we
          use water daily.</p>

        <p>Acacia Works is a recently established company based in Zambia that aims at providing
          irrigation and water solutions, products and services for the Zambian market and the surrounding
          region for residential, commercial, agricultural, industrial and dust control applications.</p>

        <p>Augue neque gravida in fermentum. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. 
        Condimentum lacinia quis vel eros donec ac. Egestas sed tempus urna et pharetra pharetra massa massa. 
        Cras sed felis eget velit. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Volutpat 
        commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Eget est lorem ipsum dolor sit 
        amet. Sit amet massa vitae tortor condimentum lacinia. Cras sed felis eget velit aliquet sagittis id. 
        Vulputate mi sit amet mauris commodo.</p>
      </div>

      <nz-divider></nz-divider>
      <div class="uniform">
        <h2 id="service">Products and Services</h2>
        <!--Products offered content under here-->
        <app-products></app-products>
      </div>
      
      <nz-divider></nz-divider>
      <div class="uniform">
        <h2 id="contact">Contact us</h2>
        <!--Contact us details-->
        <app-contact-us></app-contact-us>
      </div>
    </div>
  </nz-content>
  <nz-footer>Acacia works ©2020 Implemented By Ezeecode Technologies</nz-footer>
</nz-layout>
