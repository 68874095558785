<div nz-row>
  <div class="ts" nz-col nzSpan="8">
    <nz-card [nzBordered]="false" nzTitle="Agriculture" [nzExtra]="agriTemplate"
    nzHoverable >
      <ul>
        <li>Design and installation of irrigation system</li>
        <li>Post-up sprinklers and sprayers</li>
        <li>Drip irrigation kits</li>
        <li>Hose, Poly, Pipe connectors</li>
        <li>Hose, drive clamps</li>
      </ul>
    </nz-card>
  </div>
  <div class="ts" nz-col nzSpan="8">
    <nz-card [nzBordered]="false" nzTitle="Domestic" [nzExtra]="domesticTemplate"
      nzHoverable >
      <ul>
        <li>Post installation maintenance and servicing</li>
        <li>Dust control systems</li>
        <li>Misting systems</li>
        <li>Impact sprinklers</li>
        <li>Irrigation timers</li>
        <li>Values</li>
        <li>Irrigation tools and accessories</li>
      </ul>
    </nz-card>
  </div>
  <div class="ts" nz-col nzSpan="8">
    <nz-card [nzBordered]="false" nzTitle="Water Pumps" [nzExtra]="pumpsTemplate"
      nzHoverable >
      <ul>
        <li>Garden micro-jets</li>
        <li>Compression fittings</li>
        <li>Nylon fittings</li>
        <li>Water-filtration systems</li>
      </ul>
    </nz-card>
  </div>
</div>

<nz-modal [(nzVisible)]="isAgriVisible" nzTitle="Agriculture" 
  (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" 
  nzWidth="900px" > 
  <p>Content one</p>
  <p>Content two</p>
  <p>Content three</p>
  <p>Content three</p>
</nz-modal>

<nz-modal [(nzVisible)]="isDomesticVisible" 
  nzTitle="Domestic" (nzOnCancel)="handleCancel()" 
  (nzOnOk)="handleOk()" nzWidth="900px" >
  <p>Content one</p>
  <p>Content two</p>
  <p>Content three</p>
  <p>Content three</p>
</nz-modal>

<nz-modal [(nzVisible)]="isPumpsVisble" nzTitle="Water pump" 
  (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" 
  nzWidth="900px" >
  <p>Content one</p>
  <p>Content two</p>
  <p>Content three</p>
  <p>Content three</p>
</nz-modal>

<ng-template #agriTemplate>
  <a (click)="showAgriModal()">More</a>
</ng-template>

<ng-template #domesticTemplate>
  <a (click)="showDomesticModal()">More</a>
</ng-template>

<ng-template #pumpsTemplate>
  <a (click)="showPumpsModal()">More</a>
</ng-template>