import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  isAgriVisible = false;
  isDomesticVisible = false;
  isPumpsVisble = false;

  constructor() { }

  ngOnInit(): void {
  }

  showAgriModal(): void {
    this.isAgriVisible = true;
  }

  showDomesticModal(): void {
    this.isDomesticVisible = true;
  }

  showPumpsModal(): void {
    this.isPumpsVisble = true;
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    if (this.isAgriVisible)
      this.isAgriVisible = false;
    if (this.isDomesticVisible)
      this.isDomesticVisible = false;
    if(this.isPumpsVisble)
      this.isPumpsVisble = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    if (this.isAgriVisible)
      this.isAgriVisible = false;
    if (this.isDomesticVisible)
      this.isDomesticVisible = false;
    if(this.isPumpsVisble)
      this.isPumpsVisble = false;
  }
}
