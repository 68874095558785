import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AcaciaWorksComponent } from './acacia-works/acacia-works.component';

const routes: Routes = [    // sets up routes constant where you define your routes
  { path: '', component: AcaciaWorksComponent}
  // { path: '', loadChildren: () => import('./acacia-works/acacia-works.module').then(m => m.AcaciaWorksModule) }
];
@NgModule({
  exports: [ RouterModule ],
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ]
})
export class AppRoutingModule { }
