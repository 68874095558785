<div id="contact-layout">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.7944903362527!2d28.19355673570484!3d-12.788812150916165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x196ce5fb00c2b651%3A0x1cc639f0ae33f34d!2sAcacia%20Works!5e0!3m2!1sen!2szm!4v1604573956725!5m2!1sen!2szm" 
    frameborder="0" 
    allowfullscreen="" aria-hidden="false" 
    tabindex="0"></iframe>
  
  <div>
    <div style="margin: auto auto; align-items: center;">
      
      <h3 style="font-size: 28px; text-decoration: underline;">Address</h3>
      <p>6303 Tanganika Road (Off Mutentemuko Road),</p>
      <p>Industrial Area</p>
      <p>Kitwe, Zambia</p>

      <div style="margin: 24px 12px 5px; display: flex; justify-content: space-around;">
        <img class="social" src="assets/facebook.png" />
        <img class="social" src="assets/instagram.png" />
        <img class="social" src="assets/twitter.png" />
      </div>
    </div>
  </div>
</div>